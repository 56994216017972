import { ContainerOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Radio, Col, Form, Input, Modal, notification, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import payslip_template_101 from "../../../../assets/images/payslip_template_101.png";
import payslip_template_102 from "../../../../assets/images/payslip_template_102.png";
import payslip_template_103 from "../../../../assets/images/payslip_template_103.png";
import payslip_template_104 from "../../../../assets/images/payslip_template_104.png";
import payslip_template_105 from "../../../../assets/images/payslip_template_105.png";

const payslipImages: Record<string, string> = {
    101: payslip_template_101,
    102: payslip_template_102,
    103: payslip_template_103,
    104: payslip_template_104,
    105: payslip_template_105,
};
const { Option } = Select;

export default function PayslipTemplate(props) {


    const [form] = Form.useForm();

    const defaultPayslipTemplateList = useStoreState((state) => state.payslip.defaultPayslipTemplateList);
    const fetchdefaultPayslipTemplateList = useStoreActions((state) => state.payslip.fetchdefaultPayslipTemplateList);
    const viewPayslipTemplate = useStoreState((state) => state.payslip.viewPayslipTemplate);
    const fetchviewPayslipTemplate = useStoreActions((state) => state.payslip.fetchviewPayslipTemplate);
    const savePayslipTemplate = useStoreActions((state) => state.payslip.savePayslipTemplate);


    useEffect(() => {
        fetchdefaultPayslipTemplateList();
        fetchviewPayslipTemplate();
    }, [])

    const formSubmit = (value) => {
        savePayslipTemplate(value.payslipDefaultId);
    }


    const [templateDefaultValue, setTemplateDefaultValue] = useState<any>();

    useEffect(() => {
        setTemplateDefaultValue(viewPayslipTemplate?.payslipDefaultId);
    }, [viewPayslipTemplate])

    const templateSaveMethod = (val) => {
        savePayslipTemplate(val);
        setTemplateDefaultValue(val);
    }


    return (
        <Card title="Payslip Template">
            {/* <Row gutter={8}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form
                        layout="vertical"
                        onFinish={formSubmit}
                        id="basic-info"
                        form={form}
                    >
                        <Row gutter={8}>

                            <Col xs={24} sm={24} md={24} lg={5} xl={5}>  </Col>
                            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                <Form.Item
                                    name="payslipDefaultId"
                                    label="Template List"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select template" },
                                    ]}
                                >

                                    <Select placeholder="Select Template">
                                        {defaultPayslipTemplateList ? (
                                            defaultPayslipTemplateList.map((type, idx) => (
                                                <Option key={type.payslipDefaultId} value={type.payslipDefaultId}>
                                                    {type.payslipTemplateName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Template</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                        Save
                                    </Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div>
                        <strong>Payslip Template Name:</strong>  {viewPayslipTemplate?.payslipTemplateName} <br />
                        <strong>Payslip Template Note:</strong>  {viewPayslipTemplate?.payslipTemplateNote} <br />
                    </div>
                </Col>
            </Row> */}
            <Row>
                <Col span={24}>
                    <Radio.Group size="large" style={{ width: "100%" }} className='cardTemplateWrapper' onChange={(e) => templateSaveMethod(e.target.value)}>
                        <Row>
                            {defaultPayslipTemplateList?.map((item: any) => (
                                <Col key={item.payslipDefaultId} xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 8 }}>
                                    <Radio.Button
                                        value={item.payslipDefaultId}
                                        className={templateDefaultValue == item.payslipDefaultId ? 'ant-radio-button-wrapper-checked' : ''}
                                    >
                                        <img style={{ width: "100%" }} src={payslipImages[item.payslipDefaultId]} alt={item.payslipTemplateName} />
                                        <div className='d-flex justify-content-center'>
                                            <strong>{item.payslipTemplateName}</strong>
                                        </div>                                        
                                        <div className='d-flex justify-content-center'>
                                        <span>{item.payslipTemplateNote}</span> 
                                        </div>
                                    </Radio.Button>
                                </Col>
                            ))}

                        </Row>


                    </Radio.Group>
                </Col>
            </Row>
        </Card>
    )
}

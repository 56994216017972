import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { createClassWisePayslip, collectPayslip, fetchSinglePaySlip, fetchMultiPlePaySlip, deletePayslip, fetchdateToDateFeeHeadCollection, fetchclassDepartmentDateToDateInvoiceList, fetchledgerByDateToDateInvoiceList, downloadPayslip, fetchdateToDateStudentFeeHeadCollection, fetchdefaultPayslipTemplateList, fetchviewPayslipTemplate, savePayslipTemplate, deletePayslipPaid, createBatchPayslip, fetchviewByPayslipId, savepayslipUpdateDate } from '../../../http/payslip/payslip';
import FileSaver from 'file-saver';

export interface Payslip {
	createClassWisePayslip: Thunk<Payslip, any>
	collectPayslip: Thunk<Payslip, any>
	createBatchPayslip: Thunk<Payslip, any>

	singlePaySlip: any;
	setsinglePaySlip: Action<Payslip, any>;
	fetchSinglePaySlip: Thunk<Payslip>

	multiPlePaySlip: any;
	setmultiPlePaySlip: Action<Payslip, any>;
	fetchmultiPlePaySlip: Thunk<Payslip>

	deletePayslip: Thunk<Payslip, any>
	deletePayslipPaid: Thunk<Payslip, any>
	downloadPayslip: Thunk<Payslip, any>


	dateToDateFeeHeadCollection: any;
	setdateToDateFeeHeadCollection: Action<Payslip, any>;
	fetchdateToDateFeeHeadCollection: Thunk<Payslip, any>;

	classDepartmentDateToDateInvoiceList: any;
	setclassDepartmentDateToDateInvoiceList: Action<Payslip, any>;
	fetchclassDepartmentDateToDateInvoiceList: Thunk<Payslip, any>;

	ledgerByDateToDateInvoiceList: any;
	setledgerByDateToDateInvoiceList: Action<Payslip, any>;
	fetchledgerByDateToDateInvoiceList: Thunk<Payslip, any>

	viewByPayslipId: any;
	setviewByPayslipId: Action<Payslip, any>;
	fetchviewByPayslipId: Thunk<Payslip, any>
	savepayslipUpdateDate: Thunk<Payslip, any>

	dateToDateStudentFeeHeadCollection: any;
	setdateToDateStudentFeeHeadCollection: Action<Payslip, any>;
	feeHeadNames: any;
	setfeeHeadNames: Action<Payslip, any>;
	fetchdateToDateStudentFeeHeadCollection: Thunk<Payslip, any>;

	defaultPayslipTemplateList: any;
	setdefaultPayslipTemplateList: Action<Payslip, any>;
	fetchdefaultPayslipTemplateList: Thunk<Payslip>

	viewPayslipTemplate: any;
	setviewPayslipTemplate: Action<Payslip, any>;
	fetchviewPayslipTemplate: Thunk<Payslip>

	savePayslipTemplate: Thunk<Payslip, any>

	loading: boolean;
	setLoading: Action<Payslip, boolean>;


}

export const payslipStore: Payslip = {

	singlePaySlip: null,
	fetchSinglePaySlip: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchSinglePaySlip(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				let fData = body?.item
				if (fData?.length > 0) {
					actions.setsinglePaySlip(fData);
				} else {
					notification.warn({
						message: 'Warn',
						description: 'Payslip was collected',
						duration: 2
					});
					actions.setsinglePaySlip(null);
				}

			} else {
				notification.error({
					message: 'Error',
					description: 'No data found',
					duration: 2
				});
				actions.setsinglePaySlip(null);
				actions.setLoading(false);
			}
		} else {

		}
	}),
	setsinglePaySlip: action((state, payload) => {
		state.singlePaySlip = payload;
	}),
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),

	multiPlePaySlip: null,
	fetchmultiPlePaySlip: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchMultiPlePaySlip(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				let fData = body?.item.filter(item => item.payslipStatusInteger === 0)
				if (fData?.length > 0) {
					actions.setmultiPlePaySlip(fData);
				} else {
					notification.error({
						message: 'Error',
						description: 'No data found',
						duration: 2
					});
					actions.setmultiPlePaySlip(null);
				}

			} else {
				notification.error({
					message: 'Error',
					description: 'No data found',
					duration: 2
				});
				actions.setmultiPlePaySlip(null);
			}
		} else {
			actions.setLoading(false);
		}
	}),
	setmultiPlePaySlip: action((state, payload) => {
		state.multiPlePaySlip = payload;
	}),


	createClassWisePayslip: thunk(async (actions, payload) => {
		const response = await createClassWisePayslip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	collectPayslip: thunk(async (actions, payload) => {
		const response = await collectPayslip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	createBatchPayslip: thunk(async (actions, payload) => {
		const response = await createBatchPayslip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deletePayslip: thunk(async (actions, payload) => {
		const response = await deletePayslip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deletePayslipPaid: thunk(async (actions, payload) => {
		const response = await deletePayslipPaid(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	downloadPayslip: thunk(async (actions, payload) => {
		const response = await downloadPayslip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.blob();
			var blob = new Blob([body], { type: "application/octet-stream" });
			var fileName = "Payslip.pdf";
			FileSaver.saveAs(blob, fileName);
			return;

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	dateToDateFeeHeadCollection: null,
	fetchdateToDateFeeHeadCollection: thunk(async (actions, payload) => {
		const response = await fetchdateToDateFeeHeadCollection(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setdateToDateFeeHeadCollection(body?.item);

			} else {
				notification.error({
					message: 'Error',
					description: 'No data found',
					duration: 2
				});
				actions.setdateToDateFeeHeadCollection(null);
			}
		} else {

		}
	}),
	setdateToDateFeeHeadCollection: action((state, payload) => {
		state.dateToDateFeeHeadCollection = payload;
	}),


	classDepartmentDateToDateInvoiceList: null,
	fetchclassDepartmentDateToDateInvoiceList: thunk(async (actions, payload) => {
		const response = await fetchclassDepartmentDateToDateInvoiceList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setclassDepartmentDateToDateInvoiceList(body?.item);

			} else {
				notification.error({
					message: 'Error',
					description: 'No data found',
					duration: 2
				});
				actions.setclassDepartmentDateToDateInvoiceList(null);
			}
		} else {

		}
	}),
	setclassDepartmentDateToDateInvoiceList: action((state, payload) => {
		state.classDepartmentDateToDateInvoiceList = payload;
	}),


	ledgerByDateToDateInvoiceList: null,
	fetchledgerByDateToDateInvoiceList: thunk(async (actions, payload) => {
		const response = await fetchledgerByDateToDateInvoiceList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setledgerByDateToDateInvoiceList(body?.item);

			} else {
				notification.error({
					message: 'Error',
					description: 'No data found',
					duration: 2
				});
				actions.setledgerByDateToDateInvoiceList(null);
			}
		} else {

		}
	}),
	setledgerByDateToDateInvoiceList: action((state, payload) => {
		state.ledgerByDateToDateInvoiceList = payload;
	}),

	viewByPayslipId: null,
	fetchviewByPayslipId: thunk(async (actions, payload) => {
		const response = await fetchviewByPayslipId(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				//notification.success({ message: body.message })
				actions.setviewByPayslipId(body?.item);
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
				actions.setviewByPayslipId(null);
			}

		} else {

		}
	}),
	setviewByPayslipId: action((state, payload) => {
		state.viewByPayslipId = payload;
	}),

	savepayslipUpdateDate: thunk(async (actions, payload) => {
		const response = await savepayslipUpdateDate(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	dateToDateStudentFeeHeadCollection: null,
	feeHeadNames: {},
	fetchdateToDateStudentFeeHeadCollection: thunk(async (actions, payload) => {
		const response = await fetchdateToDateStudentFeeHeadCollection(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.studentList?.length > 0) {
				actions.setdateToDateStudentFeeHeadCollection(body?.item?.studentList);
				//actions.setfeeHeadNames(body?.item?.feeHeadNames);
				actions.setfeeHeadNames(Object.fromEntries(Object.entries(body?.item?.feeHeadNames).filter(([_, v]) => v != '')));

			} else {
				notification.error({
					message: 'Error',
					description: 'No data found',
					duration: 2
				});
				actions.setdateToDateStudentFeeHeadCollection(null);
				actions.setfeeHeadNames({});
			}
		} else {

		}
	}),
	setdateToDateStudentFeeHeadCollection: action((state, payload) => {
		state.dateToDateStudentFeeHeadCollection = payload;
	}),
	setfeeHeadNames: action((state, payload) => {
		state.feeHeadNames = payload;
	}),

	defaultPayslipTemplateList: null,
	fetchdefaultPayslipTemplateList: thunk(async (actions, payload) => {
		const response = await fetchdefaultPayslipTemplateList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				actions.setdefaultPayslipTemplateList(body?.item);
			}
		} else {

		}
	}),
	setdefaultPayslipTemplateList: action((state, payload) => {
		state.defaultPayslipTemplateList = payload;
	}),

	viewPayslipTemplate: null,
	fetchviewPayslipTemplate: thunk(async (actions, payload) => {
		const response = await fetchviewPayslipTemplate();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				actions.setviewPayslipTemplate(body?.item);
			}
		} else {

		}
	}),
	setviewPayslipTemplate: action((state, payload) => {
		state.viewPayslipTemplate = payload;
	}),

	savePayslipTemplate: thunk(async (actions, payload) => {
		const response = await savePayslipTemplate(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				actions.fetchviewPayslipTemplate();
				notification.success({ message: body.message })
			}
		} else {
			notification.error({ message:"Something went wrong" })
		}
	}),

}

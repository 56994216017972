import { Button, Card, Col, DatePicker, Form, Input, Modal, notification, Row, Select, Space, Tooltip, Table } from 'antd';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { CheckCircleOutlined, DeleteColumnOutlined, DeleteOutlined, EditOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import Text from 'antd/lib/typography/Text';
import { useState } from 'react';
import moment from 'moment';
async function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export default function BillingUpdate() {

    const fetchinstituteBillingList = useStoreActions((state) => state.customerSupport.fetchinstituteBillingList);
    const updateInstituteBilling = useStoreActions((state) => state.customerSupport.updateInstituteBilling);
    const deleteInstituteBilling = useStoreActions((state) => state.customerSupport.deleteInstituteBilling);
    const okInstituteBilling = useStoreActions((state) => state.customerSupport.okInstituteBilling);
    const instituteBillingList = useStoreState((state) => state.customerSupport.instituteBillingList)
    const [myform] = Form.useForm();
    const { Option } = Select;

    const sectionWiseAdmissionReportFormSubmit = (value) => {
        let postData: any = {
            "instituteStatus": value.instituteStatus === 3 ? null : value.instituteStatus,
            "billStatus": value.billStatus === 3 ? null : value.billStatus,
            "billingMonth": value.billingMonth === 'xx' ? '' : value.billingMonth,
            "billingYear": value.billingYear,
            "instituteOwner": "SDL"
        }
        fetchinstituteBillingList(postData);
    }
    const column = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Contact No', dataIndex: 'contactNo', key: "contactNo", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill ID', dataIndex: 'billId', key: "billId", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Type', dataIndex: 'billType', key: "billType", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Status', dataIndex: 'billStatusString', key: "billStatusString", showOnResponse: true, showOnDesktop: true },
        { title: 'Actual Bill', dataIndex: 'actualBillAmount', key: "actualBillAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Payable Amount', dataIndex: 'payableAmount', key: "payableAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Company Amount', dataIndex: 'companyAmount', key: "companyAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Partner Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Discount', dataIndex: 'discountAmount', key: "discountAmount", showOnResponse: true, showOnDesktop: true },
        { title: 'Commission', dataIndex: 'commission', key: "commission", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Tooltip title="Edit">
                            <Button type='primary' icon={<EditOutlined />} onClick={() => {
                                setIsModalVisible(true);
                                setbillId(record?.billId);
                                updateform.setFieldsValue({...record });
                            }}
                            />
                        </Tooltip>
                        <Tooltip title="Bill Ok">
                            <Button type='primary' icon={<CheckCircleOutlined />} onClick={() => {
                                setIsModalVisible2(true);
                                setbillId(record?.billId);
                            }}
                            />
                        </Tooltip>
                        <Tooltip title="Bill Delete">
                            <Button type='primary' icon={<DeleteOutlined />} onClick={() => {
                                setIsModalVisible3(true);
                                setbillId(record?.billId);
                            }}
                            />
                        </Tooltip>
                    </Space>
                </>
        },
    ];


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [isModalVisible3, setIsModalVisible3] = useState(false);

    const [billId, setbillId] = useState<any>(null);
    const [updateform] = Form.useForm();
    const [updateform2] = Form.useForm();

    let year = new Date().getFullYear();

    const updateInfoForm = (value) => {
        let payload = {
            billId,...value
        }
        updateInstituteBilling(payload);
        setTimeout(() => {
            myform.submit();
        }, 1000);
        updateform.resetFields();
        setIsModalVisible(false)
    }

    const updateInfoForm2 = (value) => {
        let payload = {
            "billId": billId,
            "imageContent": fileContent,
            "imageSave": isFile,
            "imageType": "png",
            "note": value.note,
            "paymentDate": moment(value.paymentDate).format("YYYY-MM-DD"),
            "trnId": "string"
        };
        okInstituteBilling(payload);
        setIsModalVisible2(false);
        clearFileInput(document.getElementById("upload-file"));
        setIsFile(false);
        setfileContent('');

    }

    const deleteBillForm = () => {
        let payload = {
            "billId": billId,
        };
        deleteInstituteBilling(payload);
        setIsModalVisible3(false);

    }

    const [isFile, setIsFile] = useState<boolean>(false);
    const [fileContent, setfileContent] = useState<any>('');

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const uploadPdf = (file: any) => {
        if (file.target.files[0]?.size > 1000000) {
            notification.error({ message: 'PDF size should be less than 2MB' })
            // file.target.value = null;
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            setfileContent('');
            return;
        };

        getBase64(file.target.files[0], (imageUrl) => {
            setfileContent(imageUrl.split(',')[1]);
            setIsFile(true);
        })

    }



    return (
        <>
            <Card title="Update Institute Billing">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2 }} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="instructionForm"
                            onFinish={sectionWiseAdmissionReportFormSubmit}
                            form={myform}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="billingYear"
                                        label="Year:"
                                        className="title-Text"
                                        initialValue={year}
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="billingMonth"
                                        label="Month:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select month" },
                                        ]}
                                    >
                                        <Select placeholder="Select Month" allowClear style={{ width: "100%" }}>
                                            <Option value="">Not Applicable</Option>
                                            <Option value="January-December">January-December</Option>
                                            <Option value="January-June">January-June</Option>
                                            <Option value="July-December">July-December</Option>
                                            <Option value="January">January</Option>
                                            <Option value="February">February</Option>
                                            <Option value="March">March</Option>
                                            <Option value="April">April</Option>
                                            <Option value="May">May</Option>
                                            <Option value="June">June</Option>
                                            <Option value="July">July</Option>
                                            <Option value="August">August</Option>
                                            <Option value="September">September</Option>
                                            <Option value="October">October</Option>
                                            <Option value="November">November</Option>
                                            <Option value="December">December</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="instituteStatus"
                                        label="Institute Staus:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select bill Staus" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select placeholder="Institute Staus" allowClear style={{ width: "100%" }}>
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>Inactive</Option>
                                            <Option value={3}>All</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="billStatus"
                                        label="Bill Staus:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select bill Staus" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select placeholder="Bill Status" allowClear style={{ width: "100%" }}>
                                            <Option value={1}>Paid</Option>
                                            <Option value={0}>Unpaid</Option>
                                            <Option value={3}>All</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <br />
                {instituteBillingList?.length > 0 &&
                    <Row className="m-t-mo-30">
                        <Col span="24">
                            <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns: column,
                                        dataSource: instituteBillingList,
                                        filterData: instituteBillingList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "billId",
                                        summary: function () {
                                            let actualBillAmountx = 0;
                                            let payableAmountx = 0;
                                            let companyAmountx = 0;
                                            let discountAmountx = 0;
                                            let commissionx = 0;

                                            instituteBillingList?.forEach(({ actualBillAmount, payableAmount, companyAmount, discountAmount, commission }) => {
                                                actualBillAmountx += actualBillAmount;
                                                payableAmountx += payableAmount;
                                                companyAmountx += companyAmount;
                                                discountAmountx += discountAmount;
                                                commissionx += commission;
                                            });

                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={1}>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={2}>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={3}>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={4}>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={5}>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={6}>
                                                            <Text type="danger" strong>{actualBillAmountx.toFixed(2)}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={7}>
                                                            <Text type="danger" strong>{payableAmountx.toFixed(2)}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={8}>
                                                            <Text type="danger" strong>{companyAmountx.toFixed(2)}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={9}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={10}>
                                                            <Text type="danger" strong>{discountAmountx.toFixed(2)}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={11}>
                                                            <Text type="danger" strong>{commissionx.toFixed(2)}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={12}>
                                                        </Table.Summary.Cell>
                                                    </Table.Summary.Row>

                                                </>
                                            );
                                        }
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>

                        </Col>

                    </Row>
                }
            </Card>

            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                width={"60%"}
            >
                <Form
                    layout="vertical"
                    onFinish={updateInfoForm}
                    id="update"
                    form={updateform}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert institute id" },
                                ]}
                            >
                                <Input placeholder="Institute Id" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billCycle"
                                label="Bill Cycle"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select" },
                                ]}
                            >
                                <Select placeholder="Bill Cycle">
                                    <Option key={0} value="MONTHLY">Monthly</Option>
                                    <Option key={1} value="HALF_YEARLY">Half Yearly</Option>
                                    <Option key={2} value="YEARLY">Yearly</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billType"
                                label="Bill Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select" },
                                ]}
                            >
                                <Select placeholder="Bill Type">
                                    <Option key={0} value="PER_STUDENT">Per Student</Option>
                                    <Option key={1} value="FIXED">Fixed</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billYear"
                                label="Bill Year:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                    <Option value={year - 1}>{year - 1}</Option>
                                    <Option value={year}>{year}</Option>
                                    <Option value={year + 1}>{year + 1}</Option>
                                </Select>

                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billingMonth"
                                label="Month:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select month" },
                                ]}
                            >
                                <Select placeholder="Select Month" allowClear style={{ width: "100%" }}>
                                    <Option value="">Not Applicable</Option>
                                    <Option value="January-December">January-December</Option>
                                    <Option value="January-June">January-June</Option>
                                    <Option value="July-December">July-December</Option>
                                    <Option value="January">January</Option>
                                    <Option value="February">February</Option>
                                    <Option value="March">March</Option>
                                    <Option value="April">April</Option>
                                    <Option value="May">May</Option>
                                    <Option value="June">June</Option>
                                    <Option value="July">July</Option>
                                    <Option value="August">August</Option>
                                    <Option value="September">September</Option>
                                    <Option value="October">October</Option>
                                    <Option value="November">November</Option>
                                    <Option value="December">December</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billRate"
                                label="Bill Rate"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert bill rate" },
                                ]}
                            >
                                <Input.TextArea placeholder="Bill Rate" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="totalStudent"
                                label="Total Student"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert total student" },
                                ]}
                            >
                                <Input.TextArea placeholder="Total Student" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="actualBillAmount"
                                label="Actual Bill Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert actual bill amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Actual Bill Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="discountAmount"
                                label="Discount Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert discount amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Discount Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="payableAmount"
                                label="Payable Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert payable amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Payable Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="commission"
                                label="Commission"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert commission" },
                                ]}
                            >
                                <Input.TextArea placeholder="Commission" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="companyAmount"
                                label="Company Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert company amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Company Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="note"
                                label="Note"
                                className="title-Text"

                            >
                                <Input.TextArea placeholder="Note" />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>

            </Modal>
            <Modal
                title="Update"
                visible={isModalVisible2}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update2', htmlType: 'submit' }}
                onCancel={() => {
                    setIsModalVisible2(false);
                    clearFileInput(document.getElementById("upload-file"));
                    setIsFile(false);
                    setfileContent('');
                }}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={updateInfoForm2}
                    id="update2"
                    form={updateform2}
                >
                    <Form.Item
                        name="trnId"
                        label="Trn. Id"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Trn Id" },
                        ]}
                    >
                        <Input placeholder='Tran. Id' />
                    </Form.Item>
                    <Form.Item
                        name="paymentDate"
                        label="Payment Date"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Payment Date" },
                        ]}
                    >
                        <DatePicker placeholder='Payment Date' style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="Note"
                        className="title-Text"
                        initialValue={''}
                    >
                        <Input placeholder='Note' />
                    </Form.Item>

                    <div className="ant-col ant-form-item-label"><label className="ant-form-item" >Upload Image</label></div>
                    <input className='ant-input' type="file" accept="image/png, image/gif, image/jpeg" id="upload-file" onChange={uploadPdf} />

                </Form>

            </Modal>

            <Modal
                title="Delete"
                visible={isModalVisible3}
                //  onOk={handleOk}
                okButtonProps={{ form: 'deleteModal', htmlType: 'submit' }}
                onCancel={() => {
                    setIsModalVisible3(false);
                }}
                cancelText="No"
                okText="Yes"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={deleteBillForm}
                    id="deleteModal"
                >

                    Are You Sure to Delete ?

                </Form>

            </Modal>
        </>
    )
}
import React, { useCallback, useEffect, useState } from 'react';
import { Table, Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { DeleteOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';
import { SelectGroup } from '../../../select/SelectGroup';
import { SelectCategory } from '../../../select/SelectCategory';
import xlsxParser from 'xlsx-parse-json';
import { SelectClassConfig } from '../../../select/SelectClassConfig';


export default function MultipleRegistrationWithCustomIdExcelInternational() {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [tableRowStore, setTableRowStore] = useState([]);
    const createRegistrationdatawithCustomId = useStoreActions((state) => state.student.createRegistrationdatawithCustomId)
    const createRegistrationdatawithCustomIdCheck = useStoreState((state) => state.student.createRegistrationdatawithCustomIdCheck)
    const setcreateRegistrationdatawithCustomIdCheck = useStoreActions((state) => state.student.setcreateRegistrationdatawithCustomIdCheck)
    const createRegistrationdatawithCustomIdData = useStoreState((state) => state.student.createRegistrationdatawithCustomIdCheck)
    const setcreateRegistrationdatawithCustomIdData = useStoreActions((state) => state.student.setcreateRegistrationdatawithCustomIdCheck)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const getCategoryData = (val) => {
        setStudentCategory(val);
    }
    const [checked, setChecked] = useState<boolean>(false);
    const [academicYear, setAcademicYear] = useState<any>();
    const [section, setSection] = useState<any>();
    const [group, setGroup] = useState<any>();
    const [studentCategory, setStudentCategory] = useState<any>();
    const [fileList, setFileList] = useState<any>([]);

    const onChangeFile = ({ fileList: newFileList }) => {
        newFileList[0].status = "done"
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj)
            .then(data => {
                let temp = data?.['Sheet1']?.map(function (item, index) {
                    return {
                        // hrId: item['HR ID'],
                        key: index,
                        studentRoll: item['Roll No.'],
                        registrationId: item['Registration No.'],
                        studentName: item['Name'],
                        fatherName: item["Father's Name"],
                        motherName: item["Mother's Name"],
                        studentArea: item["Student Area"],
                        guardianMobile: item['Mobile No.'],
                        studentGender: item['Gender'],
                        studentReligion: item['Religion'],
                        customStudentId: item['Student ID'],
                    }
                })
                setTableRowStore(temp)


            });

    };


    const onchangeValue: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        }, [tableRowStore]);
    const onchangeValueDropDown: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e;
            setTableRowStore(newData);
        }, [tableRowStore]);



    /****Registration Table Start*****/
    const columns = [
        {
            title: 'Student ID',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Student id" value={record?.customStudentId} name="customStudentId" className="stdName" onChange={onchangeValue("customStudentId", record, index)} />
            ),
        },
        {
            title: 'Student Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Student name" value={record?.studentName} name="studentName" className="stdName" onChange={onchangeValue("studentName", record, index)} />
            ),
        },
        {
            title: 'Student Mobile',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Student mobile" value={record?.guardianMobile} className="stdMobile" name="guardianMobile" onChange={onchangeValue("guardianMobile", record, index)} />
            ),
        },
        {
            title: 'Student Roll',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Student roll" value={record?.studentRoll} className="stdRoll" name="studentRoll" onChange={onchangeValue("studentRoll", record, index)} />
            ),
        },
        {
            title: 'Registration No',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Registration No" value={record?.registrationId} className="regRoll" name="registrationId" onChange={onchangeValue("registrationId", record, index)} />
            ),
        },
        {
            title: 'Father Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Father name" value={record?.fatherName} className="fatherName" name="fatherName" onChange={onchangeValue("fatherName", record, index)} />
            ),
        },
        {
            title: 'Mother Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Mother name" value={record?.motherName} className="motherName" name="motherName" onChange={onchangeValue("motherName", record, index)} />
            ),
        },
        {
            title: 'Religion',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Select
                    placeholder="Select studentReligion"
                    className="studentReligion"
                    value={record?.studentReligion}
                    onChange={onchangeValueDropDown("studentReligion", record, index)}
                >
                    <Option value="Islam">Islam</Option>
                    <Option value="Hinduism">Hinduism</Option>
                    <Option value="Christian">Christian</Option>
                    <Option value="Buddist">Buddist</Option>
                    <Option value="Other">Other</Option>
                </Select>
            ),
        },
        {
            title: 'Gender',
            dataIndex: 'studentGender',
            key: '',
            render: (text, record, index) => (
                <Select
                    placeholder="Select Gender"
                    className="studentGender"
                    value={record?.studentGender}
                    onChange={onchangeValueDropDown("studentGender", record, index)}
                >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                </Select>
            ),
        },
        {
            title: 'Student Area',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <Input placeholder="Student Area" value={record?.studentArea} className="studentArea" name="studentArea" onChange={onchangeValue("studentArea", record, index)} />
            ),
        },
    ];

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const submitRegForm = () => {
        if (selectedValue.length === 0) {
            message.error("Please select atleast one student");
            return;
        }
        let postData = {
            "academicYear": academicYear,
            "categoryId": studentCategory,
            "classConfigurationId": section,
            "groupId": group,
            "multipleStudentList": selectedValue,
            "smsSend": checked
        }
        if (academicYear == undefined || academicYear == null || section == undefined || section == null || group == undefined || group == null || studentCategory == undefined || studentCategory == null) {
            message.error('Please select all the fields');
            return;
        } else {
            createRegistrationdatawithCustomId(postData);
            form.resetFields();
            setChecked(false);
            setselectedRowKeys([]);
            setselectedValue([])
            setTableRowStore([]);
        }
    };

    useEffect(() => {
        if (createRegistrationdatawithCustomIdCheck) {
            setIsModalVisible(true);
        };
    }, [createRegistrationdatawithCustomIdCheck])

    /****Registration Table End******/

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([createRegistrationdatawithCustomIdData.replace(/,/g, '\n')], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "Duplicate Custom Ids.txt";
        document.body.appendChild(element);
        element.click();
    };

    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                    // onFinish={createSessionYearForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear onChange={e => setAcademicYear(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="classConfigurationId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig onChange={e => setSection(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="groupId"
                                    label="Group"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select group" },
                                    ]}
                                >
                                    <SelectGroup onChange={e => setGroup(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="categoryId"
                                    label="Student Category"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select student category" },
                                    ]}
                                >
                                    <SelectCategory onChange={e => getCategoryData(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <div className='mt-30 mt-mo-0'>
                                    <Upload
                                        listType="text"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        fileList={fileList}
                                        onChange={onChangeFile}
                                        style={{ maxWidth: 220 }}

                                    >
                                        {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload Excel File</Button></>}
                                    </Upload>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Button type="primary" onClick={() => window.open('https://res.cloudinary.com/okkhor/raw/upload/v1731859671/shebaschool/student_admission_custom_id_international.xlsx', '_blank')}>Sample File</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="m-t-mo-30" style={{ display: tableRowStore?.length > 0 ? 'block' : 'none' }}>
                <Col span="24">
                    <Table
                        columns={columns}
                        dataSource={tableRowStore}
                        bordered={true}
                        pagination={false}
                        className="p-datatable-responsive-demo"
                        rowSelection={rowSelection}
                        rowKey={record => record.key}
                    />
                    <div style={{ float: 'right' }} >

                        <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>Send SMS</Checkbox>
                        <Button type="primary" icon={<SaveOutlined />} onClick={(e) => submitRegForm()} className="m-t-mo-30 mt-30">
                            Save
                        </Button>

                    </div>

                </Col>
            </Row>
            <Modal
                title="Dupicate Student(s)"
                visible={isModalVisible}
                //onOk={handleSolve}
                //okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); setcreateRegistrationdatawithCustomIdCheck(false); setcreateRegistrationdatawithCustomIdData('') }}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Close"
                centered
                maskClosable={false}

            >
                <div style={{ maxHeight: 200, overflow: "auto" }}>
                    {createRegistrationdatawithCustomIdData}
                </div>
                <div style={{ textAlign: "right", marginTop: 15 }} >
                    <Button onClick={downloadTxtFile} type='primary'>Download List</Button>
                </div>
            </Modal>
        </>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Table, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, notification } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';



export default function MarkSheetSpecial() {
    const { Option } = Select;
    const [sectionWiseReportForm] = Form.useForm();
    const fetchSectionsWiseReport = useStoreActions((state) => state.student.fetchSectionsWiseReport);
    const sectionWiseListReport = useStoreState((state) => state.student.sectionWiseListReport);
    const loading = useStoreState((state) => state.student.loading);
    const fetchSemesterListByClassConfigId = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigId);
    const downloadMarkSheetSpecail = useStoreActions((state) => state.exam.downloadMarkSheetSpecail);
    const semesterListByClassConfigIdArray = useStoreState((state) => state.exam.semesterListByClassConfigIdArray); 
    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classconfigid: value.sectionId
        }
        fetchSectionsWiseReport(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Father', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true },
    ];

    const [examId, setexamId] = useState<any>("");

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const downloadPdf = () =>{
        if (selectedRowKeys?.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }
        let identificationIds = selectedValue?.map(item=>item.identificationId)
        let payload ={
            identificationIds: identificationIds,
            examId: examId
        }
        downloadMarkSheetSpecail(payload);
    }

    return (
        <>
            <Card title="Mark Sheet Download">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={sectionWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={e => fetchSemesterListByClassConfigId(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="examId"
                                        label="Exam List"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select semester" },
                                        ]}
                                    >
                                        <Select placeholder="Exam list" style={{ width: "100%" }} onChange={(e) => setexamId(e)}>
                                            {semesterListByClassConfigIdArray ? (
                                                semesterListByClassConfigIdArray.map((type, idx) => (
                                                    <Option key={type.examId} value={type.examId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching semester</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: sectionWiseListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: sectionWiseListReport,
                                filterData: sectionWiseListReport,
                                pagination: true,
                                bordered: true,
                                rowSelection:rowSelection
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>

                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadPdf()} > Download</Button>

                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}

import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectTeacher } from '../../../select/SelectTeacher';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { Typography } from 'antd';
import { SelectGroupbySectionThird } from '../../../select/SelectGroupbySectionThird';
import { SelectSubjectByClassNGroupMultiple } from '../../../select/SelectSubjectByClassNGroupMultiple';

export default function StaffAssign(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const { Title } = Typography;

    const teacherSubjectAssignList = useStoreState((state) => state.staff.teacherSubjectAssignList);
    const fetchteacherSubjectAssignList = useStoreActions((state) => state.staff.fetchteacherSubjectAssignList);
    const fetchlistGroupbyClassConfigthird = useStoreActions((state) => state.student.fetchlistGroupbyClassConfigthird);
    const fetchSubjectConfigurationListbyClassConfig = useStoreActions((state) => state.exam.fetchSubjectConfigurationListbyClassConfig);
    const saveteacherSubjectAssignList = useStoreActions((state) => state.staff.saveteacherSubjectAssignList);

    const deleteteacherSubjectAssignList = useStoreActions((state) => state.staff.deleteteacherSubjectAssignList);
    const loading = useStoreState((state) =>  state.staff.loading);

    useEffect(() => {
        fetchteacherSubjectAssignList();
    }, [])

    const formSubmit = (value) => {
      let payload = {
        "classConfigurationId": classConfigurationId,
        "staffId": value?.staffId,
        "subjectIds":subjectIds
      }
        saveteacherSubjectAssignList(payload);
        //form.resetFields();
        //setclassConfigurationId(null);
        //setgroupId(null);
        //setsubjectIds(undefined);
    }    
    


    const columns = [

        {
            title: 'Teachers Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Class-Shift-Section',
            dataIndex: 'classConfigurationName',
            key: 'classConfigurationName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Subject',
            dataIndex: 'subjectName',
            key: 'subjectName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteteacherSubjectAssignList(record?.teacherSubjectAssignId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    const [classConfigurationId, setclassConfigurationId] = useState<any>(null);
    const [groupId, setgroupId] = useState<any>(null);
    const [subjectIds, setsubjectIds] = useState<any>();

    const fetchsubList = (e) => {
    let payload:any = {groupId:e?.groupId, classConfigId:classConfigurationId};
    fetchSubjectConfigurationListbyClassConfig(payload);
    }

    return (
        <>
            < >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="staffId"
                                label="Select Teacher"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select teacher" },
                                ]}
                            >

                                <SelectTeacher/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="classConfigurationId"
                                label="Select Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >

                               <SelectClassConfig selected={classConfigurationId} onChange={e=>{ fetchlistGroupbyClassConfigthird(e); setclassConfigurationId(e); setgroupId(null); setsubjectIds(undefined)}}/>
                            </Form.Item>
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="groupId"
                                label="Select Group"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select group" },
                                ]}
                            >

                              <SelectGroupbySectionThird selected={groupId} onChange={e=>{setsubjectIds(undefined); setgroupId(e?.groupId); fetchsubList(e)}} />
                            </Form.Item>
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="subjectIds"
                                label="Select Subjects"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select subjects" },
                                ]}
                            >

                             <SelectSubjectByClassNGroupMultiple selected={subjectIds} onChange={e=>setsubjectIds(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {teacherSubjectAssignList === null && <Skeleton loading={loading} paragraph={{rows:10}}/>}
                {teacherSubjectAssignList!== null &&teacherSubjectAssignList?.length>0 &&
                <div className="datatable-responsive">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: teacherSubjectAssignList,
                            filterData: teacherSubjectAssignList,
                            pagination: true,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
}
            </>

        </>
    )
}
